import React, { useState } from 'react'
import styles from './Billing.module.scss'
import { SuccessModal } from '../../../../common/components/Success/SuccessModal'
import * as api from '../../../../common/utils/api'
import * as deviceUtils from '../../../../common/utils/device-utils'
import loading from '../../../../common/assets/loading.svg'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import i18n from '../../../../ii8n'

export const Billing: React.SFC = (): JSX.Element => {
  const [inProgress, setInprogress] = useState(false)
  const { userName } = useParams()
  const [signingCompleted, setSigningCompleted] = useState(false)

  const handleSigningRequest = (username: string, end: () => void): any => {
    async function checkSigningCompleted() {
      const user = await api.getUser(username)
      if (user.signingRequest === true) {
        setTimeout(checkSigningCompleted, 3000)
      } else {
        end()
      }
    }
    checkSigningCompleted()
  }

  const handleInitiateSigningRequest = async (
    username: string,
  ): Promise<any> => {
    setInprogress(true)
    await api.initiateSigningRequest(username)
    await api.sendPushNotification(username)
    handleSigningRequest(username, () => {
      setSigningCompleted(true)
      setInprogress(false)
    })
  }

  if (signingCompleted) {
    return (
      <>
        <SuccessModal initial={false} />
        <Link className={styles.logout} to="/">
          {i18n.t('billing.logout')}
        </Link>
      </>
    )
  }

  return (
    <>
      {inProgress ? (
        <>
          <div className={styles.status}>
            <span>{i18n.t('signing.initiatedSigningStatus')}</span>
            {deviceUtils.isDeviceIPhone(navigator.platform) ? (
              <>
                <br />
                <a href={`soda15://sign/${userName}`}>
                  {i18n.t('signing.initiatedSigningLinkText')}
                  &nbsp;
                  {i18n.t('signing.initiatedSigningMobileInstructions')}
                </a>
              </>
            ) : (
              <span>
                &nbsp;
                {i18n.t('signing.initiatedSigningDesktopInstructions')}
              </span>
            )}
          </div>
          <div className={styles.loadingContainer}>
            <img className={styles.loading} src={loading} alt="loading" />
          </div>
        </>
      ) : (
        <div className={styles.container}>
          <h1>{i18n.t('billing.title')}</h1>
          <div className={styles.table}>
            <label>{i18n.t('billing.date')}:</label>
            <text>18.08.2020</text>
            <input type="checkbox" defaultChecked={true} />
            <label>{i18n.t('billing.doctor')}:</label>
            <text>Hans Muster</text>
            <input type="checkbox" defaultChecked={true} />
            <label>{i18n.t('billing.patient')}:</label>
            <text>Susanne Meier</text>
            <input type="checkbox" defaultChecked={true} />
            <label>{i18n.t('billing.treatment')}:</label>
            <text>Checkup (30 mins)</text>
            <input type="checkbox" defaultChecked={true} />
            <label>{i18n.t('billing.amount')}:</label>
            <text>CHF 127.98</text>
            <input type="checkbox" defaultChecked={true} />
          </div>

          <button
            disabled={false}
            className={styles.formButton}
            onClick={(): void => {
              handleInitiateSigningRequest(userName)
            }}
          >
            {i18n.t('billing.button')}
          </button>
        </div>
      )}
    </>
  )
}
