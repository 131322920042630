import React from 'react'
import styles from './SuccessModal.module.scss'
import i18n from '../../../ii8n'
interface Props {
  initial: boolean
}

export const SuccessModal: React.SFC<Props> = ({ initial }): JSX.Element => (
  <div className={styles.container}>
    {initial ? (
      <>
        <h1>{i18n.t('authenticated.successTitle')}</h1>
        <p className={styles.text}>{i18n.t('authenticated.successMessage')}</p>
      </>
    ) : (
      <div className={styles.wrapper}>
        <h1>{i18n.t('authenticated.billingSuccessMessage')}</h1>
      </div>
    )}
  </div>
)
