/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react'
import styles from './Authenticated.module.scss'
import { Billing } from '../../components/Billing/Billing'
import { SuccessModal } from '../../../../common/components/Success/SuccessModal'
import i18n from '../../../../ii8n'

const Authenticated: React.FunctionComponent = (): JSX.Element => {
  const [billing, setBilling] = useState(false)
  return (
    <>
      {billing ? (
        <Billing />
      ) : (
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <SuccessModal initial={true} />
            <button
              disabled={false}
              className={styles.formButton}
              onClick={(): void => {
                setBilling(true)
              }}
            >
              {i18n.t('authenticated.button')}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default Authenticated
