import api from './api-configuration'

import { Configuration } from './types'

export const config: Configuration = {
  api,
  app: {
    locale: 'de',
  },
}
