import React, { ChangeEvent, Component, FormEvent } from 'react'
import { Redirect } from 'react-router-dom'
import loading from '../../../../common/assets/loading.svg'
import sodaLogo from '../../../../common/assets/soda-15-logo.svg'
import { QRcode } from '../../../../common/components/QRCode/QRcode'
import styles from './Home.module.scss'
import * as api from '../../../../common/utils/api'
import * as deviceUtils from '../../../../common/utils/device-utils'
import { User } from '../../../../common/types'
import i18n from '../../../../ii8n'
interface State {
  username: string
  password: string
  submitted: boolean
  pairingRequested: boolean
  delay: number
  authenticatedURL: any
  signingRequested: boolean
  signingCompleted: boolean
}

const pushtokenNotSet = (user: User): boolean => user.pushToken === ''

class Home extends Component<Record<string, unknown>, State> {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(props: any) {
    super(props)
    this.state = {
      username: '',
      password: '',
      submitted: false,
      pairingRequested: false,
      delay: 3000,
      authenticatedURL: null,
      signingRequested: false,
      signingCompleted: false,
    }
  }

  handlePasswordChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    this.setState({ password: event.target.value })
  }

  handleUsernameChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    this.setState({ username: event.target.value })
  }

  handlePairingRequest = (username: string, end: () => void): any => {
    async function checkPairingCompleted() {
      const user = await api.getUser(username)
      if (user.pushToken.length === 0) {
        setTimeout(checkPairingCompleted, 3000)
      } else {
        end()
      }
    }
    checkPairingCompleted()
  }

  handleSigningRequest = (username: string, end: () => void): any => {
    async function checkSigningCompleted() {
      const user = await api.getUser(username)
      if (user.signingRequest === true) {
        setTimeout(checkSigningCompleted, 3000)
      } else {
        end()
      }
    }
    checkSigningCompleted()
  }

  handlePairAndSignFlow = (): void =>
    this.handlePairingRequest(this.state.username, () => {
      this.setState(
        {
          pairingRequested: false,
          signingRequested: true,
          signingCompleted: false,
        },
        async () => {
          await api.initiateSigningRequest(this.state.username)
          await api.sendPushNotification(this.state.username)
          this.handleSigningRequest(this.state.username, () => {
            this.setState({
              signingRequested: false,
              signingCompleted: true,
            })
          })
        },
      )
    })

  handleSubmit = async (
    e: FormEvent<HTMLFormElement>,
    username: string,
  ): Promise<any> => {
    try {
      const user = await api.getUser(username)
      if (pushtokenNotSet(user)) {
        this.setState(
          {
            pairingRequested: true,
          },
          this.handlePairAndSignFlow,
        )
      } else {
        this.setState(
          {
            signingRequested: true,
            signingCompleted: false,
          },
          async () => {
            await api.initiateSigningRequest(this.state.username)
            await api.sendPushNotification(this.state.username)
            this.handleSigningRequest(this.state.username, () => {
              this.setState({
                signingRequested: false,
                signingCompleted: true,
              })
            })
          },
        )
      }
    } catch (err) {
      if (api.recordNotFound(err.response.status)) {
        try {
          await api.createUser(username)
          this.setState(
            {
              pairingRequested: true,
            },
            this.handlePairAndSignFlow,
          )
        } catch (error) {
          console.log(error.message)
        }
      }
    }
  }

  renderSigningRequested(): JSX.Element {
    return (
      <>
        <div className={styles.statusText}>
          <span>{i18n.t('signing.initiatedSigningStatus')}</span>
          {deviceUtils.isDeviceIPhone(navigator.platform) ? (
            <>
              <br />
              <a href={`soda15://sign/${this.state.username}`}>
                {i18n.t('signing.initiatedSigningLinkText')}
                &nbsp;
                {i18n.t('signing.initiatedSigningMobileInstructions')}
              </a>
            </>
          ) : (
            <span>
              &nbsp;
              {i18n.t('signing.initiatedSigningDesktopInstructions')}
            </span>
          )}
        </div>
        <div>
          <img className={styles.loading} src={loading} alt="loading" />
        </div>
      </>
    )
  }

  renderPairingRequested(): JSX.Element {
    return (
      <div className={styles.statusText}>
        {i18n.t('pairing.initiatedPairingStatus')}
        {deviceUtils.isDeviceIPhone(navigator.platform) ? (
          <>
            <a href={`soda15://pair/${this.state.username}`}>
              {i18n.t('pairing.initiatedPairingLinkText')}
            </a>
            {i18n.t('pairing.initiatedPairingMobileInstructions')}
          </>
        ) : (
          <>
            <div style={{ marginBottom: '20px' }}>
              {i18n.t('pairing.initiatedPairingDesktopInstructions')}
            </div>
            <QRcode userNameValue={this.state.username} />
          </>
        )}
      </div>
    )
  }

  renderForm(): JSX.Element {
    return (
      <form
        className={styles.form}
        onSubmit={(e: FormEvent<HTMLFormElement>): void => {
          e.preventDefault()
          this.handleSubmit(e, this.state.username)
        }}
      >
        <h1 className={styles.formTitle}>{i18n.t('loginForm.title')}</h1>
        <p>
          <input
            type="text"
            id="username"
            name="username"
            autoCorrect="off"
            autoCapitalize="none"
            placeholder={i18n.t('loginForm.usernamePlaceholder')}
            disabled={this.state.submitted}
            className={styles.formInput}
            onChange={this.handleUsernameChanged}
            value={this.state.username}
          />
        </p>
        <p>
          <input
            type="password"
            id="password"
            name="password"
            disabled={this.state.submitted}
            placeholder={i18n.t('loginForm.passwordPlaceholder')}
            className={styles.formInput}
            onChange={this.handlePasswordChanged}
            value={this.state.password}
          />
        </p>
        <div className={styles.text}>{i18n.t('loginForm.instructions')}</div>
        <p>
          <button disabled={false} className={styles.formButton}>
            {i18n.t('loginForm.button')}
          </button>
        </p>
      </form>
    )
  }

  render(): JSX.Element {
    const inProgress =
      this.state.pairingRequested || this.state.signingRequested

    if (this.state.signingCompleted === true) {
      return (
        <>
          <Redirect to={`/authenticated/${this.state.username}`} />
        </>
      )
    }

    return (
      <div className={styles.container}>
        <div className={styles.app}>
          <img className={styles.logo} src={sodaLogo} alt="logo" />
          {this.state.pairingRequested && this.renderPairingRequested()}
          {this.state.signingRequested && this.renderSigningRequested()}
          {inProgress === false && this.renderForm()}
        </div>
      </div>
    )
  }
}

export default Home
