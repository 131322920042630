import axios from 'axios'
import { config } from '../config/index'
import { User } from '../types'

const api = axios.create({
  baseURL: `${config.api.url}/`,
})

export const getUser = async (username: string): Promise<User> => {
  const response = await api.get(`users/${username}`)
  return response.data as User
}

export const createUser = async (username: string): Promise<void> => {
  await api.post('users', {
    name: username,
    pushToken: '',
    signingRequest: false,
  })
}

export const initiateSigningRequest = async (username: string): Promise<void> =>
  api.patch(`users/${username}`, {
    signingRequest: true,
  })

export const sendPushNotification = async (username: string): Promise<void> => {
  try {
    await api.post(`users/${username}/send-push-notification`)
  } catch (error) {
    console.log(error.message)
  }
}

export const recordNotFound = (responseStatus: number): boolean =>
  responseStatus === 404
